/* =Reset default browser CSS. Based on work by Eric Meyer: http://meyerweb.com/eric/tools/css/reset/index.html
-------------------------------------------------------------- */

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, font, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, button,
table, caption, tbody, tfoot, thead, tr, th, td {
  border: 0;
  font-family: inherit;
  font-size: 100%;
  font-style: inherit;
  // font-weight: 400;
  margin: 0;
  /*outline: 0;*/
  padding: 0;
  vertical-align: baseline;
}

body {
  // background-color: #fff;
  line-height: 1;
}
ol, ul {
  list-style: none;
}
table {/* tables still need 'cellspacing="0"' in the markup */
  border-collapse: separate;
  border-spacing: 0;
}
caption, th, td {
  font-weight: normal;
  text-align: left;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
}
blockquote, q {
  quotes: "" "";
}
a img {
  border: 0;
}
  /**
   * Correct `block` display not defined for any HTML5 element in IE 8/9.
   * Correct `block` display not defined for `details` or `summary` in IE 10/11
   * and Firefox.
   * Correct `block` display not defined for `main` in IE 11.
   */

article, aside, details, figcaption, figure,
footer, header, hgroup, main, menu, nav, section, main, summary {
  display: block;
}
button {
  cursor: pointer;
}

img {
  max-width: 100%;
  height: auto;
}

.spell-out {
  speak: spell-out;
}

* {
  box-sizing: border-box;
}