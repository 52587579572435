﻿.uw-site-title a:hover {
    color: #c5050c;
}

a.uw-global-name-link:hover {
    color: white;
}
#uw-top-menus.uw-horizontal .uw-nav-menu li.li-no-link {
    font-size: 14px;
    font-size: .925rem;
    font-weight: 800;
    line-height: 1.25;
    color: white;
    text-transform: uppercase;
    padding-top: .4rem; 
}

.group-border {
    border: 1px solid lightgray;
    margin-bottom: 1rem;
    padding-bottom: 1rem;
}
 
.submit-buttons{
    text-align:center;
    padding: 1rem 0;
} 
 
.add-header-button button, .add-header-button h3, .add-header-button h1, .add-header-button a{
    display:inline-block;
} 

.field-validation-error {
    color: #ff0000;

}

.field-validation-valid {
    display: none;
}


select.input-validation-error {
    border: 1px solid #ff0000;
}

input[type="checkbox"].input-validation-error {
    border: 0 none;
}

.validation-summary-errors {
    color: #ff0000;
}

.validation-summary-valid {
    display: none;
}

ul.center-error-msg {
    text-align:center;
    }

ul li.server-side-error-msg {
    list-style-type: none;
    color: #ff0000;
    }

div.tile-text-gallery label{
    display: inline-block;
    font-weight: bold;
}

.slider-nav div img{
    height:10rem; 
}

.search-submit {
  width: 100%;
  color: white;
  font-size: large;
  font-weight:bold;
  text-transform: uppercase;
  height: 40px;
  height: 2.5rem;
  background-color: #c5050c;
  -webkit-box-flex: 0;
  -webkit-flex: 0 0 auto;
     -moz-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto; }
  .search-submit svg {
    width: 19px;
    width: 1.2rem;
    height: 19px;
    height: 1.2rem;
    fill: white; }
