.uw-card {
  @include uw-flex-column(33.333333%, 500px);
  padding: $uw-padding*1;
  display: flex;
  .uw-card-content {
    background-color: $uw-white;
    img {
      width: 100%;
    }
  }
  .uw-card-copy {
    padding: 0 1rem 1rem;
  }
}