.uw-header {
  // @include uw-container($uw-max-content-width);
  display: flex;
  justify-content: center;
  background-color: white;
  &.uw-has-search {
    .uw-header-crest-title {
      width: auto;
      width: 100%;
      // padding-left: 1rem;
      @include breakpoint($uw-header-search-breakpoint) {
        width: 70%;
        padding-right: 5%;
      }
      @include breakpoint($uw-header-search-breakpoint + 140) {
        width: 75%;
        padding-right: 5%;
      }
    }
    .uw-header-search {
      padding-right: 1rem;
      @include breakpoint($uw-header-search-breakpoint) {
        width: 30%;
      }
      @include breakpoint($uw-header-search-breakpoint + 140) {
        width: 25%;
      }
    }
  }
}

.uw-header-container {
  padding: $uw-padding 0;
  flex: 0 0 100%;
  max-width: 100%;
  display: flex;
  justify-content: space-between;
  position: relative;
  @include breakpoint($uw-main-nav-breakpoint) {
    max-width: $uw-max-content-width;
  }
}

.uw-header-crest-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
}

.uw-header-crest {
  flex-basis: auto;
  margin-right: .5rem;
}
.uw-crest-svg {
  width: 2.5rem;
}
.uw-title-tagline {
  flex-basis: 100%;
}
.uw-site-title {
  -webkit-font-smoothing: antialiased;
  font-weight: 800;
  text-transform: uppercase;
  font-size: 1.25rem;
  line-height: 1.1;
  margin-bottom: .1rem;
  a {
    color: $uw-red;
    &:hover {
      text-decoration: none;
    }
  }
}
.uw-site-tagline {
  font-size: .95rem;
  font-weight: 700;
  color: $uw-gray-darker;
  text-transform: none;
  margin: .2rem 0 0 .1rem;
  line-height: 1.2;
}

.uw-search-form {
  display: flex;
}
.uw-search-input[type="text"] {
  flex: 1 1 80%;
  max-width: 80%;
}
.uw-search-submit {
  width: 2.5rem;
  height: 2.5rem;
  background-color: $uw-red;
  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: white;
  }
  flex: 0 0 auto;
}
.uw-header-search .uw-search-form {
  margin-top: 0.5rem;
}

// if DOM switch script is used to move search input within menu
li.uw-search-list-item form {
  margin-top: 0;
  margin-bottom: 0;
  padding: 0.75rem 1rem;
  border-bottom: 1px solid $uw-gray-light;
  text-align: center;
  justify-content: center;
}
li.uw-search-list-item .uw-search-input[type="text"] {
  float: none;
  width: 100%;
  max-width: 400px;
  transition: none;
}

@include breakpoint(27.5em) {
  .uw-site-title {
    font-size: 1.5rem;
  }
}
@include breakpoint(40em) {
  .uw-header-crest {
    margin-right: .8rem;
    img {
      width: 2.6rem;
    }
  }
  .uw-site-title {
    font-size: 1.7rem;
  }
  .uw-site-tagline {
    margin: -.1rem 0 0 .2rem;
  }
}
@include breakpoint(64em) {
  .uw-header-crest {
    img {
      width: 2.8rem;
    }
  }
  .uw-site-title {
    font-size: 1.8rem;
  }
  .uw-header-search .uw-search-form {
    margin-top: 1rem;
  }
}
@include breakpoint(75em) {
  .uw-header-crest {
    img {
      width: 3rem;
    }
  }
  .uw-site-title {
    font-size: 2rem;
  }
}