﻿/**
 * UW Style - Foundation Support
 * Version 0.0.1
 * DoIT - WaMS
 * This css is meant to add UW Style support to needed foundation controls.  As controls more are used, add needed styles here.
 */

/*#region General Fixes */

a:hover {
    color: #03678f;
    text-decoration: none;
}

a.uw-button:hover, button.uw-button:hover {
    color: white;
}

a.uw-button.uw-button-inverse:hover, button.uw-button.uw-button-inverse:hover {
    color: #03678f;
}

/*#endregion*/

/*#region Utility Styles*/

.no-left-padding {
    padding-left: 0;
}

.no-right-padding {
    padding-right: 0;
}

/*#endregion*/

/*#region Grid*/

.uw-row > .uw-col > .row, .uw-row > .uw-col > div > .row {
    margin: 0 -.5rem;
}

.uw-row > .uw-col > .row > .columns, .uw-row > .uw-col > div > .row > .columns {
    padding-left: .5rem;
    padding-right: .5rem;
}

/*#endregion*/

/*#region Tabs */
.uw-body {
    .tabs-container {
        margin-bottom: 1rem;

        .tabs {
            .tabs-title {
                float: left;
                margin: 0;

                > a {
                    display: block;
                    color: $anchor-color !important;
                    font-weight: 800;
                    font-family: $body-font-family;
                    text-decoration: none !important;
                    line-height: 1;
                    border: 0;

                    &:focus, a[aria-selected='true'] {
                        color: $white !important;
                        background: $uw-red;
                    }
                }

                &.is-active {
                    > a {
                        color: $white !important;
                        background: $uw-red;
                    }
                }
            }
        }

        .tabs-content {
            .tabs-panel {
                color: $body-font-color;
                @include clearfix;

                > h2 {
                    margin-top: 1rem;
                }

                > p:last-child, > li:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}

@include breakpoint(medium up) {
    .uw-body .tabs-container .tabs .tabs-title > a {
        font-size: rem-calc(18);
        padding: 1.25rem 1.5rem;
    }
}

/*#endregion*/

/*#region Accordion*/

ul.accordion {
    background-color: #f7f7f7;

    li {
        margin-bottom: 0;
        border-bottom: 1px solid #e4e4e4;

        &:last-child {
            border-bottom: none;
        }

        a.accordion-title {
            border: none;
            border-left: .25rem solid transparent;
            padding: 1rem;
            color: #0479a8;
            font-size: 1rem;
            font-weight: 700;
            line-height: 1.35;

            &:hover, &:focus {
                background-color: #f7f7f7;
                border-left: 4px solid #c5050c;
                border-left: 0.25rem solid #c5050c;
                color: #c5050c;
            }
        }

        .accordion-content.collapse {
            padding: 0;
            border-top-width: 0;
            transform: translate3d(0, 0, 0);
        }
    }
}

/*#endregion*/

/*#region Reveal*/

div.reveal {
    h3.uw-mini-bar{
        margin-top: 1rem;
    }

    .row{
        .columns{
            padding: 0;
        }
    }
}

/*#endregion*/