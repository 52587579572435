﻿// link/anchor color
$uw-anchor-color: #0479a8 !default;
$anchor-color: $uw-anchor-color;

// fonts
$uw-sans-serif-font: "Red Hat Display", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$uw-display-font: "Red Hat Text", Georgia,"Times New Roman",Times,serif !default;
$uw-serif-font: Georgia,Times New Roman,Times,serif !default;
$body-font-family: $uw-sans-serif-font;

// colors
$uw-red: #c5050c !default;
$uw-red-dark: #9b0000 !default;
$uw-gray-blue: #dadfe1 !default;
$uw-gray-lightest: #e4e4e4 !default;
$uw-gray-light: #cfcfcf !default;
$uw-gray-medium: #646569 !default;
$uw-gray-darker: #494949 !default;
$uw-gray-darkest: #282728 !default;
$uw-white: #fff !default;
$uw-black: #000 !default;
$wams-red: #c5050c !default;
$wams-yellow: #e3b505 !default;
$wams-green: #1e8b0e !default;
$black: $uw-black;
$white: $uw-white;