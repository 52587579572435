@import '../../node_modules/foundation-sites/scss/foundation';
@include foundation-everything(true);
@import '../../node_modules/motion-ui/src/motion-ui';
@import '../../node_modules/node-waves/dist/waves.min';
@import '../../node_modules/toastr/build/toastr.min';
@import 'fonts.0.0.1';
@import '_uw-style_vars.scss';
@import '_uw-style_foundation.scss';
@import '_uw-style_kendo.scss';
@import 'font-awesome.min';
@import 'uw-style';
@import 'Site';

html, body {
    height: 100%;
}

.pagecontent-placeholder {
    display: block;
    background-color: aqua;
}

.page-content-edit-button {
    text-transform:none;
    font-weight:normal;
}

fieldset > .jqte { 
    margin-top:0;
    border: 1px solid #c8c8c8;
    border-radius: 0;
    box-shadow: none;
}

.jqte_toolbar .jqte_tool.jqte_tool_1  .jqte_tool_label {
    height: 25px;   
}

.width-50 {
    width: 50%;
}

.width-100 {
    width: 100%;
}

img.carousal {
    margin:auto;
    padding-left:10px;
    padding-right:10px;
}

img.carousal-nav {
    margin:auto;
}

select{
    padding-right: 1.5rem;
}
