.uw-pagination {
  background-color: $uw-gray-lightest;
  padding: 0 1rem;
}
.uw-pagination-prev-next {
  display: flex;
  justify-content: space-between;
  align-items: center;
  > [class*="uw-pagination"] {
    display: inline-block;
  }
}

// expand for page-numbered pagination
