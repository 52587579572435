﻿/**
 * UW Style - Kendo Support
 * Version 0.0.1
 * DoIT - WaMS
 * This css is meant to add UW Style support to needed Kendo controls.  As more controls are used, add needed styles here.
 */

/*#region General Fixes */

#main{
    .k-draghandle.k-state-selected:hover, .k-ghost-splitbar-horizontal, .k-ghost-splitbar-vertical, .k-list > .k-state-highlight, .k-list > .k-state-selected, .k-marquee-color, .k-panel > .k-state-selected, .k-scheduler .k-scheduler-toolbar .k-state-selected, .k-scheduler .k-today.k-state-selected, .k-state-selected, .k-state-selected:link, .k-state-selected:visited{
        background-color: $uw-red;
        border-color: $uw-red;
    }
}

/*#endregion*/

/*#region Grid*/

.k-grid {
    margin-bottom: 2rem;
    border: 1px solid #dbdbdb;

    .k-grid-header {
        background-color: #f7f7f7;
        background-image: none;

        .k-header{
            background-color: #f7f7f7;
            background-image: none;
            border: none;
            font-weight: bold;

            .k-icon {
                vertical-align: baseline;
            }
        }

        .k-grid-header-wrap{
            border: none;

           .k-header { 
                background-color: #f7f7f7;
                background-image: none;
                border: none;
                font-weight: bold;

                .k-icon {
                    vertical-align: baseline;
                }
            }
        }
    }

    .k-grid-content tr:hover{
        background-image: none;
        background-color: $uw-gray-blue;
    }

    tbody tr:hover {
        background-image: none;
        background-color: $uw-gray-blue;
    }

    .k-pager-wrap{
        background: #f7f7f7;
        color: #494949;
        border-top: 1px solid $uw-gray-lightest;

        .k-pager-nav .k-icon{
            margin-bottom: 2px;
        }

        .k-pager-numbers {
            line-height: inherit;

            li{
                margin: 0;
                font-size: inherit;
            }
        }
    }
}

/*#endregion*/

